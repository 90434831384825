export enum RouteActionMode {
    UNDEFINED,
    EDIT,
    NEW,
    VIEW,
    ADD,
    COPY,
    CREATE,
    CLONE,
    IMPORT,
}
